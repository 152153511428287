import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../ControllerContext';
import {OrderSummaryDataHook} from '../../OrderSummary';
import s from './AdditionalFees.scss';
import classnames from 'classnames';
import {AdditionalFee} from '@wix/wixstores-graphql-schema-node';

const AdditionalFeeLine: React.FC<{additionalFee: Partial<AdditionalFee>}> = ({additionalFee}) => {
  return (
    <dl style={{paddingBottom: '0px'}}>
      <dt>
        <span data-hook={OrderSummaryDataHook.AdditionalFeesName}>{additionalFee.name}</span>
      </dt>
      <dd data-hook={OrderSummaryDataHook.AdditionalFeesPrice}>{additionalFee.convertedFormattedTotalPrice}</dd>
    </dl>
  );
};

const SingleAdditionalFee: React.FC<{additionalFee: Partial<AdditionalFee>}> = ({additionalFee}) => {
  return (
    <div data-hook={OrderSummaryDataHook.SingleAdditionalFeeRoot} className={s.title}>
      <AdditionalFeeLine additionalFee={additionalFee} key={additionalFee.code} />
    </div>
  );
};

const MultipleAdditionalFees: React.FC<{additionalFees: Partial<AdditionalFee>[]}> = ({additionalFees}) => {
  const {t} = useTranslation();

  return (
    <div data-hook={OrderSummaryDataHook.MultipleAdditionalFeesRoot} className={classnames(s.divider, s.root)}>
      <div data-hook={OrderSummaryDataHook.AdditionalFeesTitle} className={s.title}>
        {t('cart.additionalFees')}
      </div>
      {additionalFees.map((fee) => {
        return <AdditionalFeeLine additionalFee={fee} key={fee.code} />;
      })}
    </div>
  );
};

export const AdditionalFees = () => {
  const {additionalFees} = useControllerProps().cartStore.cart;

  return additionalFees.length > 1 ? (
    <MultipleAdditionalFees additionalFees={additionalFees} />
  ) : (
    <SingleAdditionalFee additionalFee={additionalFees[0]} />
  );
};
